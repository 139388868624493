import React, {useEffect, useState} from 'react';

import axios from "axios";
import * as Icon from "react-feather";

import Footer from "./views/Footer";
import Header from "./views/Header";
import Sidebar from "./views/Sidebar";
import ajax from "./data/ajax";
import Loading from "./views/components/Loading";
import ScrollToTop from "./views/components/ScrollToTop";
import SidebarItemsLss from "./views/SidebarItemsLss";
import {PERMISSIONS} from "./components/permissions";
import {AFFILIATES_ID} from "./components/affiliates";
import {mainGroups} from "./components/user_groups";
import RoutesGuest from "./views/RoutesGuest";
import RoutesMainLss from "./views/RoutesMainLss";
import RoutesMain from "./views/RoutesMain";
import SidebarItems from "./views/SidebarItems";

const App = props => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [error] = useState(null);
    const [user, setUser] = useState([]);

    const [menuItems, setMenuItems] = useState([]);
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();

            axios.get(window.trackerBaseUrl + "auth", requestConfig).then(response => {
                // console.log("response", response)
                if (response.data === null) {
                    setIsLoaded(true);
                    setIsAuth(false);
                } else {
                    setIsAuth(true);
                    const u = response.data;
                    u.getCountries = () => u.app_data.countries;
                    u.allowManagers = () => u.app_data.managers.length > 1;
                    u.getManagers = () => u.app_data.managers.sort((a, b) => a.id - b.id);
                    u.getManagerById = (id) => u.app_data.managers.find(a => a.id === id);
                    u.allowManagerGroups = () => u.app_data.manager_groups.length > 1;
                    u.getManagerGroups = () => u.app_data.manager_groups.sort((a, b) => a.id - b.id);
                    u.getAffiliateAccounts = () => u.app_data.affiliate_accounts.sort((a, b) => a.id - b.id);
                    u.getAffiliateNetworks = () => u.app_data.affiliate_networks.sort((a, b) => a.id - b.id);
                    u.getTrafficSourceAccounts = () => u.app_data.traffic_source_accounts.sort((a, b) => a.id - b.id);
                    u.getTrafficSourceNetworks = () => u.app_data.traffic_source_networks.sort((a, b) => a.id - b.id);
                    u.getTrafficSourceNetworkById = (id) => u.app_data.traffic_source_networks.find(a => a.id === id);
                    u.allowPermission = (x) => u.permissions.includes(x);
                    u.inGroups = (x) => x.includes(u.group_id);
                    setUser(u);

                    let user = response.data;

                    let uName = (response.data.name).trim();
                    uName = uName.length > 0 ? uName : response.data.email
                    setUserName(uName);

                    let mItems = [];
                    if (process.env.REACT_APP_TYPE === "lss") {
                        mItems = SidebarItemsLss();
                    } else if (process.env.REACT_APP_TYPE === "cabinet") {
                        mItems = SidebarItems(user);
                    }
                    if (process.env.NODE_ENV === "development") {
                        mItems.push({
                            title: 'Development',
                            link: 'dev',
                            icon: <Icon.Command/>,
                            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
                        })
                    }
                    setMenuItems(mItems);

                    setIsLoaded(true);
                }
            })
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return <div>Ошибка: {error.message}</div>
    } else if (!isLoaded) {
        return <Loading/>
    } else if (!isAuth) {
        return <RoutesGuest />;
    } else {
        return (
            <div className="wrapper">
                <Sidebar menuItems={menuItems}/>

                <div className="main">
                    <Header userName={userName}/>

                    <main className="content">
                        <ScrollToTop/>

                        {process.env.REACT_APP_TYPE === "lss" && <RoutesMainLss user={user} />}
                        {process.env.REACT_APP_TYPE === "cabinet" && <RoutesMain user={user} props={props} />}
                    </main>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default App;
