import React from 'react';
import * as Icon from 'react-feather';
import {PERMISSIONS} from "../components/permissions";
import {mainGroups} from "../components/user_groups";
import {AFFILIATES_ID} from "../components/affiliates";

export default function SidebarItems(user) {
    let mItems = [];

    mItems.push(
        {
            title: 'Dashboard Push',
            link: 'dashboard/push',
            icon: <Icon.TrendingUp/>,
            visible: user.allowPermission(PERMISSIONS.DASHBOARD_MAIN),
        },
        {
            title: 'Dashboard Smartlink',
            link: 'dashboard/smartlink',
            icon: <Icon.TrendingUp/>,
            visible: user.allowPermission(PERMISSIONS.DASHBOARD_SMARTLINK),
        },
        {
            title: 'Dashboard Clickadu',
            link: 'dashboard/clickadu',
            icon: <Icon.TrendingUp/>,
            visible: user.allowPermission(PERMISSIONS.DASHBOARD_CLICKADU),
        },
        {
            title: 'Dashboard Zeydoo',
            link: 'dashboard/zeydoo',
            icon: <Icon.TrendingUp/>,
            visible: user.allowPermission(PERMISSIONS.DASHBOARD_ZEYDOO),
        },
        {
            title: 'Dashboard Total',
            link: 'dashboard/total',
            icon: <Icon.TrendingUp/>,
            // visible: user.allowPermission(PERMISSIONS.OWNER),
        },
        {
            title: 'Data validation Summary',
            link: 'data-validation/summary',
            icon: <Icon.CheckSquare/>,
            visible: user.allowPermission(PERMISSIONS.OWNER),
        },
        {
            title: 'Data validation',
            link: 'data-validation/by-account?latest=1&valid=0',
            icon: <Icon.CheckSquare/>,
        },
    );
    if (user.inGroups(mainGroups)) {
        user.getTrafficSourceAccounts().sort((a, b) => a.name.localeCompare(b.name)).forEach((item) => {
            mItems.push({
                // title: 'Source: ' + item.name,
                title: item.name,
                link: 'ex-stat/account/' + item.id,
                icon: <Icon.Sliders/>,
            })
        });
    }
    mItems.push(
        {
            title: 'Landings',
            link: 'collections/landings',
            icon: <Icon.Layers/>,
            visible: user.inGroups(mainGroups),
        },
        {
            title: 'Landings 2',
            link: 'collections/landings2',
            icon: <Icon.Layers/>,
            // visible: user.inGroups(mainGroups),
        },
        {
            title: 'Landings 3',
            link: 'collections/landings3',
            icon: <Icon.Layers/>,
            visible: user.inGroups(mainGroups),
        },
        {
            title: 'Domains',
            link: 'collections/domains',
            icon: <Icon.Layers/>,
            visible: user.inGroups(mainGroups),
        },
        {
            title: 'Users',
            link: 'collections/users',
            icon: <Icon.Layers/>,
            visible: user.allowManagers(),
        },
        {
            title: 'Traffic Sources',
            link: 'collections/traffic-sources',
            icon: <Icon.Layers/>,
        },
        {
            title: 'Campaigns',
            link: 'ex-stat/campaign',
            icon: <Icon.Layers/>,
            visible: user.inGroups(mainGroups),
        },
        {
            title: 'Zones (PropushMe)',
            link: 'zone/propushme',
            icon: <Icon.Layers/>,
            visible: user.getAffiliateAccounts().filter(v => AFFILIATES_ID.PROPUSHME === (v.network_id + '')).length > 0,
        },
        {
            title: 'Zones (RollerAds)',
            link: 'zone/rollerads',
            icon: <Icon.Layers/>,
            visible: user.getAffiliateAccounts().filter(v => AFFILIATES_ID.ROLLERADS === (v.network_id + '')).length > 0,
        },
        {
            title: 'Zones (Zeydoo)',
            link: 'zone/zeydoo',
            icon: <Icon.Layers/>,
            visible: user.getAffiliateAccounts().filter(v => AFFILIATES_ID.ZEYDOO === (v.network_id + '')).length > 0,
        },
        {
            title: 'Zones (Galaksion)',
            link: 'zone/galaksion',
            icon: <Icon.Layers/>,
            visible: user.getAffiliateAccounts().filter(v => AFFILIATES_ID.GALAKSION === (v.network_id + '')).length > 0,
        },
        {
            title: 'Postback Zones',
            link: 'postback-setup/zones',
            icon: <Icon.Layers/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: 'Postback Custom',
            link: 'postback-setup/custom',
            icon: <Icon.Layers/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: 'Postbacks',
            link: 'postbacks',
            icon: <Icon.Layers/>,
        },
        {
            title: 'Report 1',
            link: 'reports/report_1',
            icon: <Icon.FileText/>,
            visible: user.inGroups(mainGroups),
        },
        {
            title: 'Rates',
            link: 'segment-rate',
            icon: <Icon.Layers/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: 'Rates settings',
            link: 'segment-defaults',
            icon: <Icon.Layers/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: 'NFT Collections',
            link: 'nft/collections',
            icon: <Icon.Archive/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: 'NFT Tokens',
            link: 'nft/tokens',
            icon: <Icon.Image/>,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
    );

    return mItems;
}
