import React from 'react';
// import PropTypes from 'prop-types';

export default function Card({children}) {
    return (
        <div className="card">
            <div className="card-body">
                {children}
            </div>
        </div>
    )
}

// Card.propTypes = {
//     children: PropTypes.oneOfType([
//         PropTypes.arrayOf(PropTypes.element),
//         PropTypes.element.isRequired,
//         PropTypes.string.isRequired
//     ])
// }
