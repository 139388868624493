export const PERMISSIONS = {
    OWNER: "Permission_Owner",
    DEVELOPER: "Permission_Developer",
    DASHBOARD_MAIN: "Permission_Dashboard_Main",
    DASHBOARD_SMARTLINK: "Permission_Dashboard_Smartlink",
    DASHBOARD_CLICKADU: "Permission_Dashboard_Clickadu",
    DASHBOARD_ZEYDOO: "Permission_Dashboard_Zeydoo",
    USER_CREATE: "Permission_User_Create",
    USER_UPDATE: "Permission_User_Update",
    TRAFFIC_SOURCE_UPDATE: "Permission_TrafficSource_Update",
};
