import React from 'react';
import {Link} from "react-router-dom";

import ZoneData from "../../components/ZoneData";
import Card from "./Сard";
import {useGetRequest} from "../../data/RequestData";
import LoadingWrapper from "./LoadingWrapper";
import ExternalLink from "./ExternalLink";
import {isServicePostbackUrl, resolvePhpPostbackDomain} from "../../components/postback_url";
import {networkIsGalaksion, networkIsPropush, networkIsRollerads, networkIsZeydoo} from "../../components/affiliates";
import {mainGroups} from "../../components/user_groups";

export default function ZoneInfo({zone_id, user}) {
    const [{data, isLoading, error}] = useGetRequest('ex-stat/zone/' + zone_id);

    const postbackDomain = resolvePhpPostbackDomain(user);

    const isPropush = networkIsPropush(data['pub_account_type']);
    const isRollerads = networkIsRollerads(data['pub_account_type']);
    const isZeydoo = networkIsZeydoo(data['pub_account_type']);
    const isGalaksion = networkIsGalaksion(data['pub_account_type']);

    const domain = "globatds.com";
    const linkSW1 = 'https://' + domain + '/sw-' + data['origin_zone_id'] + '.js';
    const linkSW2 = 'https://' + domain + '/swn-' + data['origin_zone_id'] + '.js';

    let linkSource = "-";
    let linkLog = "-";
    if (isPropush) {
        linkSource = "http://" + postbackDomain + "/zone_postback.php?zone=" + data['origin_zone_id'];
        linkLog = "http://" + postbackDomain + "/conversion_logs/" + data['origin_zone_id'] + '.txt';
    } else if (isRollerads) {
        linkSource = "http://" + postbackDomain + "/zone_postback.php?zone=ra_" + data['origin_zone_id'];
        linkLog = "http://" + postbackDomain + "/conversion_logs/ra_" + data['origin_zone_id'] + '.txt';
    } else if (isZeydoo) {
        linkSource = "http://" + postbackDomain + "/zone_postback.php?zone=zd_" + data['origin_zone_id'];
        linkLog = "http://" + postbackDomain + "/conversion_logs/zd_" + data['origin_zone_id'] + '.txt';
    } else if (isGalaksion) {
        linkSource = "http://" + postbackDomain + "/zone_postback.php?zone=gl_" + data['origin_zone_id'];
        linkLog = "http://" + postbackDomain + "/conversion_logs/gl_" + data['origin_zone_id'] + '.txt';
    }

    const linkToPropush = "https://app.propush.me/#/site/" + data['origin_site_id'] + "/zone/" + data['origin_zone_id'];

    const linkEditPropushInfo = "/ex-stat/zone/edit-propush/" + data['id'];
    const linkEditLocalInfo = "/ex-stat/zone/edit/" + data['id'];
    const linkPostbackCreate = "/postback-setup/zones/create/" + data['id'];
    const linkPostbackUpdate = "/postback-setup/zones/edit/" + data['conversion_setup_id'];

    const createLinkLocal = url => {
        return <Link to={url}>{url}</Link>
    }

    return (
        <LoadingWrapper isLoaded={!isLoading} error={error}>
            <Card>
                <h3>Zone info:</h3>
                <div><b>ID:</b> {data['origin_zone_id']}</div>
                <div><b>Title:</b> {data['title']}</div>
                <div><b>Account:</b> {data['pub_account_name']}</div>
                {!isZeydoo && <>
                    <div><b>Direction:</b> {ZoneData.directionName(data['direction_id'])}</div>
                </>}
                {isPropush && <>
                    <div><b>Revenue Model:</b> {ZoneData.revenueModelName(data['revenue_model'])}</div>
                    {ZoneData.isPushDirection(data['direction_id']) && <div>
                        <b>Worker files:</b>
                        <ul>
                            <li><ExternalLink url={linkSW1}/></li>
                            <li><ExternalLink url={linkSW2}/></li>
                        </ul>
                    </div>
                    }
                </>}

                {(data['postback_url'] || isGalaksion) && <>
                    <div><b>Postback:</b> {data['postback_url']}</div>

                    {(!isServicePostbackUrl(data['postback_url'], user) || isGalaksion) && <>
                        {user.inGroups(mainGroups) &&
                            <div><b>Personal postback source:</b> <ExternalLink url={linkSource}/>
                        </div>}
                        <div><b>Postback log:</b> <ExternalLink url={linkLog}/></div>
                    </>}
                </>}

                {isPropush && <>
                    <div><b>Link to propush:</b> <ExternalLink url={linkToPropush}/></div>
                    <div><b>Link to edit propush info:</b> {createLinkLocal(linkEditPropushInfo)}</div>
                </>}
                <div><b>Link to edit local info:</b> {createLinkLocal(linkEditLocalInfo)}</div>
                <div><b>Link to setup conversion:</b> {data['conversion_setup_id'] !== null
                    ? createLinkLocal(linkPostbackUpdate)
                    : createLinkLocal(linkPostbackCreate)
                }</div>
                <div><b>Link to preview postbacks:</b> {createLinkLocal("/postbacks?zone=" + data['id'])}</div>
            </Card>
        </LoadingWrapper>
    )
}
