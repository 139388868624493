import {getZoneRevenueModelName} from "../data/static_data";
import ZoneData from "./ZoneData";
import DataData from "./DataData";
import ListData from "./ListData";
import DomainData from "./DomainData";
import {TRAFFIC_SOURCES_ID} from "./traffic_sources";
import {formatManagerName} from "./user";

const addParamsToUrl = (url, params) => {
    const searchParams = new URLSearchParams(params);
    const paramsString = searchParams.toString();
    if (paramsString.length > 0) {
        const c = url.indexOf("?") === -1 ? "?" : "&";

        return url + c + paramsString;
    }

    return url;
}

const createUrl = path => window.trackerBaseUrl + path

const getCustomUrl = (path, params) => {
    return addParamsToUrl(createUrl(path), params);
}

const getZonesListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/zone-list'), params);
}

const getZoneSitesListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/zone-sites-list'), params);
}

const getNetworksListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/networks-list'), params);
}

/**
 * @deprecated
 */
const getAccountsListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/accounts-list'), params);
}

const getCampaignsListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/campaigns-list'), params);
}

const getPlacementsListUrl = (params) => {
    return addParamsToUrl(createUrl('ex-stat/placements-list'), params);
}

const getClickaduTargetingListUrl = (params) => {
    return addParamsToUrl(createUrl('clickadu/targeting'), params);
}

const getZeydooOffersListUrl = (params) => {
    return addParamsToUrl(createUrl('zeydoo/offers'), params);
}

const getPostbackRejectStatuses = (params) => {
    return addParamsToUrl(createUrl('postbacks/reject-statuses'), params);
}

const getPostbackZones = (params) => {
    return addParamsToUrl(createUrl('postbacks/zones'), params);
}

const _stringVal = (v) => v + ''

const formatZonesDataForSelect = (data) => {
    return data.map(i => {
        let title = i.custom_title.length > 0
            ? i.custom_title + " (" + i.title + ")"
            : i.title;

        return {
            value: _stringVal(i.id),
            label: i.origin_zone_id + " / " + title + " / " + getZoneRevenueModelName(i.custom_revenue_model)
        }
    });
}

const formatZonesDataForSelect2 = (data) => {
    return data.map(i => {
        let title = i.custom_title.length > 0
            ? i.custom_title + " (" + i.title + ")"
            : i.title;

        return {
            value: _stringVal(i.id),
            label: i.origin_zone_id + " / " + title,
        }
    });
}

const formatZoneSitesDataForSelect = (data) => {
    return data.map(i => {
        return {
            value: _stringVal(i.origin_id),
            label: i.account_name + " / " + i.origin_id + " / " + i.full_url
        }
    });
}

const formatAccountsDataForSelect = (data) => data.map(i => {
    return {value: _stringVal(i.id), label: i.id + " / " + i.name}
});

const formatAccountsDataForSelectPostback = (data) => [{value: '', label: 'Custom postback'}].concat(data.map(i => {
    return {value: _stringVal(i.id), label: i.id + ' / ' + i.name + ' / ' + i.postback}
}));

const formatCountriesDataForSelect = (data) => {
    return data.map(i => {
        return {value: i.code, label: i.code + " / " + i.name}
    });
}

const formatCampaignsDataForSelect = (data) => {
    return data.map(i => {
        return {
            value: _stringVal(i.id),
            label: i.origin_campaign_id + " / " + i.name
        };
    });
}

const formatCampaignsOriginDataForSelect = (data) => {
    return data.map(i => {
        return {
            value: _stringVal(i.origin_campaign_id),
            label: i.origin_campaign_id + " / " + i.name
        };
    });
}

const formatPlacementsDataForSelect = (data) => {
    return data.map(i => {
        return {
            value: _stringVal(i.id),
            label: i.origin_placement_id
        };
    });
}

const formatManagersDataForSelect = (data) => {
    console.log(data)
    return data.map(i => {
        return {
            value: _stringVal(i.id),
            label: i.id + " / " + formatManagerName(i)}
    });
}

const formatIdTitle = (data) => {
    return data.map(i => {
        return {value: _stringVal(i.id), label: i.title}
    });
}

const formatIdIdTitle = (data) => {
    return data.map(i => {
        return {value: _stringVal(i.id), label: i.id + " / " + i.title}
    });
}

const formatIdIdName = (data) => {
    return data.map(i => {
        return {value: _stringVal(i.id), label: i.id + " / " + i.name}
    });
}

const formatValue = (data) => data.map(i => {
    return {value: _stringVal(i), label: _stringVal(i)}
})

const formatValueWithEmpty = (data) => {
    const d = formatValue(data)
    d.unshift({value: '___empty___', label: '<none>'});
    return d;
}

const hoursDataForSelect = () => formatValue([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);

const directionsForSelect = () => {
    return [
        {value: ZoneData.DIRECTION_PUSH, label: ZoneData.directionName(ZoneData.DIRECTION_PUSH)},
        {value: ZoneData.DIRECTION_TB, label: ZoneData.directionName(ZoneData.DIRECTION_TB)},
    ];
}

const revenueModelsForSelect = () => {
    return [
        {value: ZoneData.REVENUE_MODEL_CPS, label: ZoneData.revenueModelName(ZoneData.REVENUE_MODEL_CPS)},
        {value: ZoneData.REVENUE_MODEL_REV_SHARE, label: ZoneData.revenueModelName(ZoneData.REVENUE_MODEL_REV_SHARE)},
    ];
}

const boolForSelect = () => {
    return [
        {value: ListData.BOOL_NO, label: ListData.boolName(ListData.BOOL_NO)},
        {value: ListData.BOOL_YES, label: ListData.boolName(ListData.BOOL_YES)},
    ];
}

const dataTypesPushForSelect = () => {
    return [
        {value: DataData.TYPE_PUSH_COST, label: DataData.typeName(DataData.TYPE_PUSH_COST)},
        {value: DataData.TYPE_PUSH_REVENUE, label: DataData.typeName(DataData.TYPE_PUSH_REVENUE)},
        {value: DataData.TYPE_PUSH_REVENUE_TB, label: DataData.typeName(DataData.TYPE_PUSH_REVENUE_TB)},
    ];
}

const dataTypesTBForSelect = () => {
    return [
        {value: DataData.TYPE_CAMPAIGN_TEST_COST_TB, label: DataData.typeName(DataData.TYPE_CAMPAIGN_TEST_COST_TB)},
        {value: DataData.TYPE_REALTIME_TB_COST, label: DataData.typeName(DataData.TYPE_REALTIME_TB_COST)},
        {value: DataData.TYPE_TB_COST, label: DataData.typeName(DataData.TYPE_TB_COST)},
        {value: DataData.TYPE_REALTIME_TB_REVENUE, label: DataData.typeName(DataData.TYPE_REALTIME_TB_REVENUE)},
        {value: DataData.TYPE_TB_REVENUE, label: DataData.typeName(DataData.TYPE_TB_REVENUE)},
    ];
}

const domainPlansForSelect = () => {
    return [
        {value: DomainData.PLAN_NO, label: DomainData.getPlan(DomainData.PLAN_NO)},
        {value: DomainData.PLAN_FREE, label: DomainData.getPlan(DomainData.PLAN_FREE)},
        {value: DomainData.PLAN_ENTERPRISE, label: DomainData.getPlan(DomainData.PLAN_ENTERPRISE)},
    ];
}

const domainTypesForSelect = () => {
    return [
        {value: DomainData.TYPE_REDIRECT, label: DomainData.getType(DomainData.TYPE_REDIRECT)},
        {value: DomainData.TYPE_SMARTLINK, label: DomainData.getType(DomainData.TYPE_SMARTLINK)},
        {value: DomainData.TYPE_PUSH, label: DomainData.getType(DomainData.TYPE_PUSH)},
    ];
}

const domainStatusForSelect = () => {
    return [
        {value: DomainData.STATUS_OLD, label: DomainData.getStatus(DomainData.STATUS_OLD)},
        {value: DomainData.STATUS_ACTIVE, label: DomainData.getStatus(DomainData.STATUS_ACTIVE)},
        {value: DomainData.STATUS_NEW, label: DomainData.getStatus(DomainData.STATUS_NEW)},
    ];
}

const trafficSourcesForSelect = () => {
    return [
        {value: TRAFFIC_SOURCES_ID.ADSTERRA, label: "Adsterra"},
        {value: TRAFFIC_SOURCES_ID.CLICKADU, label: "Clickadu"},
        {value: TRAFFIC_SOURCES_ID.ADCASH, label: "Adcash"},
        {value: TRAFFIC_SOURCES_ID.GALAKSION, label: "Galaksion"},
        {value: TRAFFIC_SOURCES_ID.POPADS, label: "Popads"},
        {value: TRAFFIC_SOURCES_ID.TRAFFIC_STARS, label: "Traffic Stars"},
        {value: TRAFFIC_SOURCES_ID.MONETIZER, label: "Advertizer"},
        {value: TRAFFIC_SOURCES_ID.ADMAVEN, label: "Admaven"},
    ];
}

export const UrlCreator = {
    _custom: getCustomUrl,
    zones: getZonesListUrl,
    sites: getZoneSitesListUrl,
    networks: getNetworksListUrl,
    accounts: getAccountsListUrl,
    campaigns: getCampaignsListUrl,
    placements: getPlacementsListUrl,
    clickadu_targeting: getClickaduTargetingListUrl,
    zeydoo_offers: getZeydooOffersListUrl,
    postbackRejectStatuses: getPostbackRejectStatuses,
    postbackZones: getPostbackZones,
};

export const SelectDataFormatter = {
    zones: formatZonesDataForSelect,
    zones2: formatZonesDataForSelect2,
    sites: formatZoneSitesDataForSelect,
    networks: formatIdIdName,
    accounts: formatAccountsDataForSelect,
    accountsForPostback: formatAccountsDataForSelectPostback,
    countries: formatCountriesDataForSelect,
    campaigns: formatCampaignsDataForSelect,
    campaignsIdOrigin: formatCampaignsOriginDataForSelect,
    placements: formatPlacementsDataForSelect,
    managerGroups: formatIdIdTitle,
    managers: formatManagersDataForSelect,
    hours: hoursDataForSelect,
    directions: directionsForSelect,
    revenueModels: revenueModelsForSelect,
    bool: boolForSelect,
    dataTypesPush: dataTypesPushForSelect,
    dataTypesTB: dataTypesTBForSelect,
    id_title: formatIdTitle,
    id_id_title: formatIdIdTitle,
    id_id_name: formatIdIdName,
    value: formatValue,
    valueWithEmpty: formatValueWithEmpty,
    domainPlansForSelect: domainPlansForSelect,
    domainTypesForSelect: domainTypesForSelect,
    domainStatusForSelect: domainStatusForSelect,
    trafficSources: trafficSourcesForSelect,
};
