import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

import Card from "../../components/Сard";
import SelectMulti from "../../components/select/SelectMulti";
import Table from "../../components/table/Table";
import Container from "../../components/Container";
import TableParams from "./TrafficSourcesListTableParams";
import {SelectDataFormatter} from "../../../components/select_data";
import Filter from "../../../components/filter";
import {useSelect} from "../../../components/input-hook";
import BackendRequest from "../../../data/BackendRequest";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import ButtonFilter from "../../components/ButtonFilter";
import TableColumns from "../../components/table/TableColumns";
import {PERMISSIONS} from "../../../components/permissions";

const TrafficSourcesList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_traffic_sources';
    const tableParams = TableParams(user);

    const defaultHiddenColumns = [];
    if (!user.allowManagers()) {
        defaultHiddenColumns.push(tableParams.keyManagerName);
    }
    if (!user.allowPermission(PERMISSIONS.TRAFFIC_SOURCE_UPDATE)) {
        defaultHiddenColumns.push(tableParams.keyButtonEdit);
    }
    const [tableUserHiddenColumns, setTableUserHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterIsEnabled = useSelect([]);
    const inputFilterNetwork = useSelect([]);
    const inputFilterManager = useSelect([]);
    const inputFilterManagerGroup = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('traffic-source', e => {
        return e;
    });

    useEffect(() => {
        if (!isLoadedFilterData) {
            let filters = [
                {
                    key: 'valid',
                    data: SelectDataFormatter.bool(),
                    input: inputFilterIsEnabled,
                },
                {
                    key: 'network_id',
                    data: SelectDataFormatter.networks(user.getTrafficSourceNetworks()),
                    input: inputFilterNetwork,
                },
            ];
            if (user.allowManagerGroups()) {
                filters.push({
                    key: 'manager_group_id',
                    data: SelectDataFormatter.managerGroups(user.getManagerGroups()),
                    input: inputFilterManagerGroup,
                });
            }
            if (user.allowManagers()) {
                filters.push({
                    key: 'manager_id',
                    data: SelectDataFormatter.managers(user.getManagers()),
                    input: inputFilterManager,
                });
            }

            Filter.collectParamsForFilter(location, filters)

            setIsLoadedFilterData(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                status: inputFilterIsEnabled.value,
                network_id: inputFilterNetwork.value,
                manager_group_id: inputFilterManagerGroup.value,
                manager_id: inputFilterManager.value,
            });
        }
    }, [
        isLoadedFilterData,
        inputFilterNetwork.value,
        inputFilterManager.value,
        inputFilterManagerGroup.value,
        inputFilterIsEnabled.value,
        filterButton
    ]);

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(Object.assign({}, dataRequestParams));
        }
    }, [dataRequestParams]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Traffic sources list"}>
            <Card>
                <div className="row">
                    <div className="col-md-2">
                        <b>Is enabled</b>
                        <SelectMulti {...inputFilterIsEnabled.bind}/>
                    </div>
                    <div className="col-md-2">
                        <b>Network</b>
                        <SelectMulti {...inputFilterNetwork.bind}/>
                    </div>
                    {user.allowManagerGroups() && <div className="col-md-2">
                        <b>Manager group</b>
                        <SelectMulti {...inputFilterManagerGroup.bind}/>
                    </div>}
                    {user.allowManagers() && <div className="col-md-2">
                        <b>Manager</b>
                        <SelectMulti {...inputFilterManager.bind}/>
                    </div>}
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                    </div>
                    <div className="col-md-6">&nbsp;</div>
                    <div className="col-md-3 text-right">
                        <TableColumns
                            storageKey={localStorageTableKey}
                            columns={tableParams.columns}
                            setUserTableHiddenColumns={v => setTableUserHiddenColumns(v)}
                        />
                    </div>
                </div>
            </Card>

            <Card>
                <Table
                    data={data}
                    {...tableParams}
                    hiddenColumns={tableHiddenColumns}
                />
            </Card>
        </Container>
    );
}

export default TrafficSourcesList;
