import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import {consoleLog} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePutRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import {useInput, useSelect} from "../../../components/input-hook";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";
import DivError from "../../components/form/DivError";
import SelectSingle from "../../components/select/SelectSingle";
import {SelectDataFormatter} from "../../../components/select_data";

const UserFormEdit = ({user}) => {
    let {id} = useParams();

    const inputStatus = useSelect('1');
    // const inputGroup = useSelect('');
    const inputEmail = useInput('');
    const inputPassword = useInput('');
    const inputInitials = useInput('');
    const inputName = useInput('');
    const inputMessenger = useInput('');
    const inputNotes = useInput('');

    const [{data, isLoading, error}] = useGetRequest('user/' + id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePutRequest('user/' + id);

    useEffect(() => {
        console.log(dataErrors)
        let errors = {};
        dataErrors.forEach(v => {
            // if (v.field === 'status') {
            //     errors.status = v.message
            // } else if (v.field === 'email') {
            //     errors.email = v.message
            // } else if (v.field === 'name') {
            //     errors.name = v.message
            // } else if (v.field === 'initials') {
            //     errors.initials = v.message
            // } else if (v.field === 'messenger') {
            //     errors.messenger = v.message
            // } else if (v.field === 'notes') {
            //     errors.notes = v.message
            // }
            errors[v.field] = v.message;
        })

        inputStatus.setError(errors.status ?? '');
        inputEmail.setError(errors.email ?? '');
        inputPassword.setError(errors.new_password ?? '');
        inputName.setError(errors.name ?? '');
        inputInitials.setError(errors.initials ?? '');
        inputMessenger.setError(errors.messenger ?? '');
        inputNotes.setError(errors.notes ?? '');
    }, [dataErrors]);

    const setFromResponse = (result) => {
        consoleLog(result);

        inputStatus.setValue(result.status ?? '')
        // inputGroup.setValue(result.group_id ?? '')
        inputEmail.setValue(result.email ?? '')
        inputPassword.setValue(result.new_password ?? '')
        inputName.setValue(result.name ?? '')
        inputInitials.setValue(result.initials ?? '')
        inputMessenger.setValue(result.messenger ?? '')
        inputNotes.setValue(result.notes ?? '')
    }

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            inputStatus.setOptions(SelectDataFormatter.bool());
            // inputGroup.setOptions(SelectDataFormatter.managerGroups(user.getManagerGroups()));
            setFromResponse(data);
        }
    }, [data]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            status: inputStatus.value,
            email: inputEmail.value,
            new_password: inputPassword.value,
            name: inputName.value,
            initials: inputInitials.value,
            messenger: inputMessenger.value,
            notes: inputNotes.value,
        };
        setRequestData(data);
    }

    return (
        <Container title={"Edit user: " + id}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {
                            errorUpdate &&
                            <AlertDanger messagePrefix={errorUpdate['name'] + ': '} message={errorUpdate['message']}/>
                        }
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <DivFormGroupRow label="Enabled" labelAttributes={{htmlFor: "inputStatus"}}>
                            <SelectSingle {...inputStatus.bind} />
                            <DivError error={inputStatus.error}/>
                        </DivFormGroupRow>

{/*
                        {user.allowManagerGroups() &&
                            <DivFormGroupRow label="Group" labelAttributes={{htmlFor: "inputGroup"}}>
                                <SelectSingle {...inputGroup.bind} />
                                <DivError error={inputGroup.error}/>
                            </DivFormGroupRow>
                        }
*/}

                        <DivFormGroupRow label="Email" labelAttributes={{htmlFor: "inputEmail"}}>
                            <input
                                type="text"
                                id="inputEmail"
                                placeholder="Email"
                                className={"form-control " + (inputEmail.error.length > 0 ? "is-invalid" : '')}
                                {...inputEmail.bind}
                            />
                            <DivError error={inputEmail.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Name" labelAttributes={{htmlFor: "inputName"}}>
                            <input
                                type="text"
                                id="inputName"
                                placeholder="Name"
                                className={"form-control " + (inputName.error.length > 0 ? "is-invalid" : '')}
                                {...inputName.bind}
                            />
                            <DivError error={inputName.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Initials" labelAttributes={{htmlFor: "inputInitials"}}>
                            <input
                                type="text"
                                id="inputInitials"
                                placeholder="Initials"
                                className={"form-control " + (inputInitials.error.length > 0 ? "is-invalid" : '')}
                                {...inputInitials.bind}
                            />
                            <DivError error={inputInitials.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Password" labelAttributes={{htmlFor: "inputPassword"}}>
                            <input
                                type="text"
                                // type="password"
                                autoComplete="off"
                                id="inputPassword"
                                placeholder="Set new password"
                                className={"form-control " + (inputPassword.error.length > 0 ? "is-invalid" : '')}
                                {...inputPassword.bind}
                            />
                            <DivError error={inputPassword.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Telegram" labelAttributes={{htmlFor: "inputMessenger"}}>
                            <input
                                type="text"
                                id="inputMessenger"
                                placeholder="Messenger"
                                className={"form-control " + (inputMessenger.error.length > 0 ? "is-invalid" : '')}
                                {...inputMessenger.bind}
                            />
                            <DivError error={inputMessenger.error}/>
                        </DivFormGroupRow>


                        <DivFormGroupRow label="Notes" labelAttributes={{htmlFor: "inputNotes"}}>
                             <textarea
                                 id="inputNotes"
                                 placeholder="Notes"
                                 className={"form-control " + (inputNotes.error.length > 0 ? "is-invalid" : '')}
                                 {...inputNotes.bind}
                             ></textarea>
                            <DivError error={inputNotes.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow>
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </DivFormGroupRow>
                    </form>
                </LoadingWrapper>
            </Card>
        </Container>
    );
}

export default UserFormEdit;
