export const formatManagerName = (u) => (u.name.length > 0 ? u.name + " / " : "") + u.email;

export const printManagerName = (user, id) => {
    let name = id;

    const u = user.getManagerById(id)
    if (u !== undefined) {
        name = formatManagerName(u);
    }
    return name;
};
