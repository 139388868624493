import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/table/Table";
import DateRangePicker from "../../../components/DateRangePicker";
import TableParams from "./ListTableParams";
import GroupByParams from "./ListGroupByParams";
import ajax from "../../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../../components/select_data";
import {consoleLogResponse} from "../../../../components/development";
import {ArrayIntersection, ArrayRemoveValues, ObjectRemoveEmptyForUrl} from "../../../../components/helpers";
import {useCheckboxInput, useSelect} from "../../../../components/input-hook";
import Filter from "../../../../components/filter";
import LoadingWrapper from "../../../components/LoadingWrapper";
import SelectMulti from "../../../components/select/SelectMulti";
import ButtonFilter from "../../../components/ButtonFilter";
import TableColumns from "../../../components/table/TableColumns";
import FilterGroupByMulti from "../../../components/FilterGroupByMulti";
import BackendRequest from "../../../../data/BackendRequest";
import {formulaECPA, formulaProfit, formulaROI, formulaTbCost} from "../../../../components/table/TableListFunctions";
import {DATA_TYPE_PUSH} from "../../../../components/const_data_type";
import {PERMISSIONS} from "../../../../components/permissions";
import {mainGroups} from "../../../../components/user_groups";
import {printManagerName} from "../../../../components/user";

const ExStatDashboardList = ({user}) => {
    const dataType = DATA_TYPE_PUSH;

    const location = useLocation();
    const locationPathname = location.pathname

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_dashboard_1';
    const tableParams = TableParams();

    const defaultHiddenColumns = [];
    const [tableGroupHiddenColumns, setTableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setTableUserHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableGroupHiddenColumns, tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const groupByParams = GroupByParams(user);
    const [filterGroupBy, setFilterGroupBy] = useState([groupByParams.keyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [diffDates, setDiffDates] = useState({
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [diffError, setDiffError] = useState('');

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterAccount = useSelect([]);
    const inputFilterCampaign = useSelect([]);
    const inputFilterCountry = useSelect([]);
    const inputFilterManager = useSelect([]);
    const inputFilterManagerGroup = useSelect([]);
    const inputFilterPubAccount = useSelect([]);
    const inputFilterType = useSelect([]);
    const inputFilterZone = useSelect([]);
    const inputCompare = useCheckboxInput(false);
    const inputCompareCustom = useCheckboxInput(false);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('ex-stat/dashboard', e => {
        e.impressions = Number(e.impressions);
        e.conversions = Number(e.conversions);
        e.cost = Number(e.cost);
        e.revenue = Number(e.revenue);
        e.revenue_tb = Number(e.revenue_tb);
        e.revenue_total = e.revenue + e.revenue_tb;
        e.profit = formulaProfit(e.revenue, e.cost);
        e.profit_tb = formulaProfit(e.revenue_total, e.cost);
        e.roi = formulaROI(formulaProfit(e.revenue, e.cost), e.cost);
        e.total_roi = formulaROI(formulaProfit(e.revenue_total, e.cost), e.cost);
        e.tb_of_cost = formulaTbCost(e.revenue_tb, e.cost);
        e.ecpa = formulaECPA(e.cost, e.conversions);

        if ('prev_impressions' in e) {
            e.prev_impressions = Number(e.prev_impressions);
            e.prev_conversions = Number(e.prev_conversions);
            e.prev_cost = Number(e.prev_cost);
            e.prev_revenue = Number(e.prev_revenue);
            e.prev_revenue_tb = Number(e.prev_revenue_tb);
            e.prev_revenue_total = e.prev_revenue + e.prev_revenue_tb;
            e.prev_profit = formulaProfit(e.prev_revenue, e.prev_cost);
            e.prev_profit_tb = formulaProfit(e.prev_revenue_total, e.prev_cost);
            e.prev_roi = formulaROI(formulaProfit(e.prev_revenue, e.prev_cost), e.prev_cost);
            e.prev_total_roi = formulaROI(formulaProfit(e.prev_revenue_total, e.prev_cost), e.prev_cost);
            e.prev_tb_of_cost = formulaTbCost(e.prev_revenue_tb, e.prev_cost);
            e.prev_ecpa = formulaECPA(e.prev_cost, e.prev_conversions);
        }

        if ('pub_account' in e && !('account' in e)) {
            e.account = e.pub_account;
        }

        if (tableParams.keyManagerID in e) {
            e[tableParams.keyManagerName] = printManagerName(user, e[tableParams.keyManagerID])
        }

        return e;
    });

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.zones({'data_type': dataType}), requestConfig),
                axios.get(UrlCreator.campaigns({'data_type': dataType}), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const [rZones, rCampaigns] = results;

                let filters = [
                    {
                        key: 'account',
                        data: SelectDataFormatter.accounts(user.getTrafficSourceAccounts()),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'country',
                        data: SelectDataFormatter.countries(user.getCountries()),
                        input: inputFilterCountry,
                    },
                    {
                        key: 'zone',
                        data: SelectDataFormatter.zones(rZones.data),
                        input: inputFilterZone,
                    },
                    {
                        key: 'campaign',
                        data: SelectDataFormatter.campaigns(rCampaigns.data),
                        input: inputFilterCampaign,
                    },
                    {
                        key: 'pub_account',
                        data: SelectDataFormatter.accounts(user.getAffiliateAccounts()),
                        input: inputFilterPubAccount,
                    },
                    {
                        key: 'type',
                        data: SelectDataFormatter.dataTypesPush(),
                        input: inputFilterType,
                    },
                ];
                if (user.allowManagerGroups()) {
                    filters.push({
                        key: 'manager_group',
                        data: SelectDataFormatter.managerGroups(user.getManagerGroups()),
                        input: inputFilterManagerGroup,
                    });
                }
                if (user.allowManagers()) {
                    filters.push({
                        key: 'manager',
                        data: SelectDataFormatter.managers(user.getManagers()),
                        input: inputFilterManager,
                    });
                }

                let params = Filter.collectParamsForFilter(location, filters)

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);
                if (("group_by" in searchParams)) {
                    let allowedGroupBy = ArrayIntersection(searchParams.group_by.split(','), groupByParams.items.map(e => e.key))
                    params.group_by = allowedGroupBy.join(',');
                    setFilterGroupBy(allowedGroupBy);
                } else {
                    params.group_by = filterGroupBy.join(',');
                }

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                if ("diff_from" in searchParams && "diff_to" in searchParams) {
                    setDiffDates({
                        start: searchParams.diff_from,
                        end: searchParams.diff_to,
                    });
                    inputCompare.setValue(true);

                    const int = moment(params.date_to).diff(moment(params.date_from), 'days') + 1;
                    const d1 =  moment(params.date_from).subtract(int, 'days').format('YYYY-MM-DD');
                    const d2 =  moment(params.date_to).subtract(int, 'days').format('YYYY-MM-DD');
                    if (d1 !== searchParams.diff_from || d2 !== searchParams.diff_to) {
                        inputCompareCustom.setValue(true);

                    }
                } else {
                    const r = moment(params.date_to).diff(moment(params.date_from), 'days') + 1;
                    setDiffDates({
                        start: moment(params.date_from).subtract(r, 'days').format('YYYY-MM-DD'),
                        end: moment(params.date_to).subtract(r, 'days').format('YYYY-MM-DD'),
                    });
                }

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const groupChangedColumns = [
            tableParams.keyDate,
            tableParams.keyDateYear,
            tableParams.keyDateMonth,
            tableParams.keyDateWeek,
            tableParams.keyCountry,
            tableParams.keyManagerGroupID,
            tableParams.keyManagerGroupName,
            tableParams.keyManagerID,
            tableParams.keyManagerName,
            tableParams.keyAccountID,
            tableParams.keyAccountName,
            tableParams.keyPubAccountID,
            tableParams.keyPubAccountName,
            tableParams.keyZoneID,
            tableParams.keyZoneName,
            tableParams.keyZoneTbID,
            tableParams.keyZoneTbName,
            tableParams.keyCampaignID,
            tableParams.keyCampaignName,
            tableParams.keyType,
            tableParams.keyTypeName,
        ]

        filterGroupBy.forEach(e => {
            // eslint-disable-next-line default-case
            switch (e) {
                case groupByParams.keyDate:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDate))
                    break;
                case groupByParams.keyDateYear:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDateYear))
                    break;
                case groupByParams.keyDateMonth:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDateMonth))
                    break;
                case groupByParams.keyDateWeek:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDateWeek))
                    break;
                case groupByParams.keyCountry:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyCountry))
                    break;
                case groupByParams.keyManagerGroup:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyManagerGroupID, tableParams.keyManagerGroupName))
                    break;
                case groupByParams.keyManager:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyManagerID, tableParams.keyManagerName))
                    break;
                case groupByParams.keyAccount:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyAccountID, tableParams.keyAccountName))
                    break;
                case groupByParams.keyPubAccount:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyPubAccountID, tableParams.keyPubAccountName))
                    break;
                case groupByParams.keyCampaign:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyCampaignID, tableParams.keyCampaignName))
                    break;
                case groupByParams.keyZone:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyZoneID, tableParams.keyZoneName))
                    break;
                case groupByParams.keyZoneTb:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyZoneTbID, tableParams.keyZoneTbName))
                    break;
                case groupByParams.keyType:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyType, tableParams.keyTypeName))
                    break;
            }
        })

    }, [filterGroupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            let diff_from =  "";
            let diff_to =  "";

            if (inputCompare.value === true) {
                if (inputCompareCustom.value === true) {
                    diff_from =  diffDates.start;
                    diff_to =  diffDates.end;
                } else {
                    const r = moment(calendarDates.end).diff(moment(calendarDates.start), 'days') + 1;

                    diff_from =  moment(calendarDates.start).subtract(r, 'days').format('YYYY-MM-DD');
                    diff_to =  moment(calendarDates.end).subtract(r, 'days').format('YYYY-MM-DD');
                }
            }

            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                diff_from: diff_from,
                diff_to: diff_to,
                manager_group: inputFilterManagerGroup.value,
                manager: inputFilterManager.value,
                account: inputFilterAccount.value,
                pub_account: inputFilterPubAccount.value,
                country: inputFilterCountry.value,
                zone: inputFilterZone.value,
                campaign: inputFilterCampaign.value,
                type: inputFilterType.value,
                group_by: filterGroupBy.join(','),
            })

            let err = '';
            if (inputCompare.value === true) {
                const r1 = moment(calendarDates.end).diff(moment(calendarDates.start), 'days') + 1;
                const r2 = moment(diffDates.end).diff(moment(diffDates.start), 'days') + 1;
                if (r1 !== r2) {
                    err = 'Report date range (' + r1 + ' days) != Diff date range (' + r2 + ' days)';
                }
            }
            setDiffError(err)
        }
    }, [
        isLoadedFilterData,
        filterGroupBy,
        calendarDates,
        diffDates,
        inputFilterAccount.value,
        inputFilterCampaign.value,
        inputFilterCountry.value,
        inputFilterManagerGroup.value,
        inputFilterManager.value,
        inputFilterPubAccount.value,
        inputFilterType.value,
        inputFilterZone.value,
        inputCompare.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(Object.assign({}, dataRequestParams, {"dashboard": dataType}));
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Dashboard Push"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                            <label className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" {...inputCompare.bind}/>
                                <span className="custom-control-label">Compare with period</span>
                            </label>
                            {inputCompare.value === true && <>
                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" {...inputCompareCustom.bind}/>
                                    <span className="custom-control-label">Select custom period</span>
                                </label>
                                {inputCompareCustom.value === true && <>
                                    <div>
                                        <DateRangePicker
                                            dates={diffDates}
                                            updateDates={v => setDiffDates(v)}
                                        />
                                        <span className={"text-info"}>{diffError}</span>
                                    </div>
                                </>}
                            </>}
                        </div>
                        <div className="col-md-2">
                            <b>Affiliate network</b>
                            <SelectMulti {...inputFilterPubAccount.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Traffic source</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        {user.allowManagerGroups() && <div className="col-md-2">
                            <b>Manager group</b>
                            <SelectMulti {...inputFilterManagerGroup.bind}/>
                        </div>}
                        {user.allowManagers() && <div className="col-md-2">
                            <b>Manager</b>
                            <SelectMulti {...inputFilterManager.bind}/>
                        </div>}
                        <div className="col-md-2">
                            <b>Country</b>
                            <SelectMulti {...inputFilterCountry.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Zone</b>
                            <SelectMulti {...inputFilterZone.bind}/>
                        </div>
                        {user.inGroups(mainGroups) && <div className="col-md-2">
                            <b>Campaign</b>
                            <SelectMulti {...inputFilterCampaign.bind}/>
                        </div>}
                        {user.allowPermission(PERMISSIONS.DEVELOPER) && <div className="col-md-2">
                            <b>Type</b>
                            <SelectMulti {...inputFilterType.bind}/>
                        </div>}
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setTableUserHiddenColumns(v)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <FilterGroupByMulti
                                items={groupByParams.items}
                                value={filterGroupBy}
                                updateValue={v => setFilterGroupBy(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default ExStatDashboardList;
