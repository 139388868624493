import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";

import Login from "./pages/Login";
import RegisterRecaptcha from "./pages/RegisterRecaptcha";

export default function RoutesGuest() {
    return <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<RegisterRecaptcha/>}/>
        <Route path="*" element={<Navigate to="/login"/>}/>
    </Routes>
}
