import React from 'react';
import * as Icon from 'react-feather';

const SidebarItemsLss = () => {
    const lssItems = [
        {
            title: 'Statistic',
            link: 'stats',
            icon: <Icon.BarChart2/>,
        },
        {
            title: 'Rates',
            link: 'rates',
            icon: <Icon.Activity/>,
        },
        {
            title: 'Settings',
            link: 'settings',
            icon: <Icon.User/>,
        },
    ];

    return lssItems;
}

export default SidebarItemsLss;
