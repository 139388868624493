import React, {useState, useEffect} from 'react';
import {useParams, useNavigate, Link} from "react-router-dom";
import RangeSlider from "react-bootstrap-range-slider";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import {SelectDataFormatter} from "../../../components/select_data";
import {isDebug, Dump, consoleLog, consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePostRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import SelectSingle from "../../components/select/SelectSingle";
import {useCheckboxInput, useInput, useSelect} from "../../../components/input-hook";
import ZoneInfo from "../../components/ZoneInfo";
import PreviewTable from "./PreviewTable";
import {round} from "../../../components/Math";
import {
    CustomSettingsTemplate,
    MaxNegativeMargin,
    PhpToJsonInfo,
    PostbackPlaceholdersInfo,
    SubscriptionMPTemplate
} from "./elements";
import DivError from "../../components/form/DivError";
import Alert from "../../components/Alert";
import JsonValidate from "../../components/JsonValidate";
import {isServicePostbackUrl, postbackUrlIsGood, resolvePhpPostbackDomain} from "../../../components/postback_url";

const PostbackSetupZonesFormCreate = ({user}) => {
    let {zone_id} = useParams();

    const navigate = useNavigate();

    const [minMarginPercentage, setMinMarginPercentage] = useState(0);

    const [{data, isLoading, error}] = useGetRequest('ex-stat/zone/' + zone_id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePostRequest('ex-stat/zone-conversion');

    const defaultMaxValue = 80

    const inputEnabled = useCheckboxInput(true);
    const inputAccountId = useSelect('');
    const inputPostbackUrl = useInput('');
    const inputForwardPercentage = useInput('50');
    const inputMarginPercentage = useInput('50');
    const inputPrecision = useInput('4');
    const inputRates = useInput('');
    const inputMinValue = useInput('');
    const inputMaxValue = useInput(defaultMaxValue + '');
    const inputCustomSettings = useInput('');
    const inputSubsPM = useInput('');
    const inputNegativeMargin = useCheckboxInput(false);

    const setFromResponse = (result) => {
        consoleLog(result);

        inputEnabled.setValue(parseInt(result.enabled) === 1);
        inputForwardPercentage.setValue(result.forward_percentage ? result.forward_percentage : 0);
        inputMarginPercentage.setValue(result.margin_percentage ? result.margin_percentage : 0);
        inputAccountId.setValue(result.account_id ? result.account_id + '' : '');
        inputPostbackUrl.setValue(result.url ? result.url : '');
        inputPrecision.setValue(result.precision ? result.precision : -1);
        inputRates.setValue(result.rate_table ? JSON.stringify(result.rate_table) : '');
        inputMinValue.setValue(result.min_value ? result.min_value + '' : '');
        inputMaxValue.setValue(result.max_value ? result.max_value + '' : '');
        inputCustomSettings.setValue(result.custom_settings ? JSON.stringify(result.custom_settings) : '');
        inputSubsPM.setValue(result.subs_pm ? JSON.stringify(result.subs_pm) : '');
    }

    useEffect(() => {
        if (inputAccountId.value === null) {
            inputAccountId.setValue('');
        }
    }, [inputAccountId.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const promises = [];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                inputAccountId.setOptions(SelectDataFormatter.accountsForPostback(user.getTrafficSourceAccounts()));
            });

            //setFromResponse(data);
        }
    }, [data]);


    useEffect(() => {
        if (dataUpdate) {
            setFromResponse(dataUpdate);
            window.scrollTo(0, 0);

            navigate('/postback-setup/zones/edit/' + dataUpdate.id);
        }
    }, [dataUpdate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let minValue = inputMinValue.value.replace(/,/g, '.');
        if (minValue !== '') {
            minValue = Number(minValue);
            if (minValue === 0) {
                minValue = null;
            }
        } else {
            minValue = null;
        }

        let maxValue = inputMaxValue.value.replace(/,/g, '.');
        if (maxValue !== '') {
            maxValue = Number(maxValue);
            if (maxValue === 0) {
                maxValue = defaultMaxValue;
            }
            if (minValue !== null && minValue > maxValue) {
                maxValue = minValue;
            }
        } else {
            maxValue = defaultMaxValue;
        }

        const d = {
            zone_id: data.id,
            name: 'Zone ' + data.origin_zone_id,
            enabled: inputEnabled.value === true ? 1 : 0,
            forward_percentage: parseInt(inputForwardPercentage.value),
            margin_percentage: parseInt(inputMarginPercentage.value),
            account_id: inputAccountId.value + '',
            url: inputAccountId.value === '' ? inputPostbackUrl.value : '',
            precision: inputPrecision.value < 0 ? null : parseInt(inputPrecision.value),
            rate_table: inputRates.value === '' ? null : JSON.parse(inputRates.value),
            min_value: minValue,
            max_value: maxValue,
            custom_settings: inputCustomSettings.value === '' ? null : JSON.parse(inputCustomSettings.value),
            subs_pm: inputSubsPM.value === '' ? null : JSON.parse(inputSubsPM.value),
        };
        setRequestData(d);
    }

    useEffect(() => {
        setMinMarginPercentage(inputNegativeMargin.value ? MaxNegativeMargin : 0);
        if (!inputNegativeMargin.value && (inputMarginPercentage.value + 0 < 0)) {
            inputMarginPercentage.setValue('0');
        }
    }, [inputNegativeMargin.value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Create zone conversion: " + data['origin_zone_id']}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    {errorUpdate !== null && <AlertDanger messagePrefix="Save error:" message={errorUpdate['message']}/>}

                    {data['conversion_setup_id'] !== null && <Alert
                        messagePrefix="Creation error:"
                        message={<>This zone already has a conversion settings. Go to <Link to={"/postback-setup/zones/edit/" + data['conversion_setup_id']}>{"/postback-setup/zones/edit/" + data['conversion_setup_id']}</Link></>}
                        className={"alert-warning"}
                    />}

                    {data['conversion_setup_id'] === null && <>
                        <form onSubmit={handleSubmit}>
                            {
                                data['postback_url'] && (postbackUrlIsGood(data['postback_url'], user, data['origin_zone_id']) === false) &&
                                <AlertDanger messagePrefix="Zone postback:" message={data['postback_url']}/>
                            }
                            {
                                data['postback_url'] && postbackUrlIsGood(data['postback_url'], user, data['origin_zone_id']) &&
                                <AlertSuccess
                                    messagePrefix="Zone postback:"
                                    message={data['postback_url'] + (
                                        isServicePostbackUrl(data['postback_url'], user)
                                            ? ''
                                            : '; see source: http://' + resolvePhpPostbackDomain(user) + '/zone_postback.php?zone=' + data['origin_zone_id']
                                    )}
                                />
                            }
                            {
                                dataUpdate && <AlertSuccess message="Data updated successfully"/>
                            }

                            <div className="form-group">
                                <label><input type="checkbox" {...inputEnabled.bind} /> Enable outcome postbacks</label>
                            </div>

                            {inputEnabled.value === true && <>
                                <div className="form-group">
                                    <label>Advertiser account</label>
                                    <SelectSingle {...inputAccountId.bind} />
                                    <DivError error={inputAccountId.error}/>
                                </div>

                                {inputAccountId.value === '' && <div className="form-group">
                                    <label htmlFor="inputPostbackUrl">Postback Url</label>
                                    <input
                                        type="text"
                                        id="inputPostbackUrl"
                                        placeholder="Postback url"
                                        className={"form-control " + (inputPostbackUrl.error.length > 0 ? "is-invalid" : '')}
                                        {...inputPostbackUrl.bind}
                                    />
                                    <PostbackPlaceholdersInfo url={inputPostbackUrl.value}/>
                                    <div>
                                        <Link to="" onClick={e => {
                                            e.preventDefault();
                                            inputPostbackUrl.setValue("https://binomnet3.com/click?cnv_id={click_id}&payout={payout}&cnv_status=&cnv_status2=")
                                        }}>{"Apply binom link: https://binomnet3.com/click?cnv_id={click_id}&payout={payout}&cnv_status=&cnv_status2="}</Link>
                                    </div>
                                    <DivError error={inputPostbackUrl.error}/>
                                </div>}

                                <div className="form-group">
                                    <label>Round precision</label>
                                    <RangeSlider
                                        size='lg'
                                        // tooltipLabel={currentValue => `${currentValue}%`}
                                        tooltipLabel={currentValue => {
                                            const v = 0.55555555555;
                                            if (currentValue === -1) {
                                                return v;
                                            }
                                            return 'round(' + v + ', ' + currentValue + ') = ' + round(v, currentValue)
                                        }}
                                        tooltip='on'
                                        min={-1}
                                        max={8}
                                        value={inputPrecision.value}
                                        onChange={changeEvent => inputPrecision.setValue(changeEvent.target.value)}
                                    />
                                    <DivError error={inputPrecision.error}/>
                                </div>

                                <div className="form-group">
                                    <label>Forward Conversion Percentage</label>
                                    <RangeSlider
                                        size='lg'
                                        tooltipLabel={currentValue => `${currentValue}%`}
                                        tooltip='on'
                                        min={0}
                                        max={100}
                                        value={inputForwardPercentage.value}
                                        onChange={changeEvent => inputForwardPercentage.setValue(changeEvent.target.value)}
                                    />
                                    <DivError error={inputForwardPercentage.error}/>
                                </div>

                                <div className="form-group">
                                    <label>Margin Percentage</label>
                                    <div>
                                        <label className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                   className="custom-control-input" {...inputNegativeMargin.bind} />
                                            <span className="custom-control-label">I want to pay more than I get!</span>
                                        </label>
                                    </div>
                                    <RangeSlider
                                        size='lg'
                                        tooltipLabel={currentValue => `${currentValue}%`}
                                        tooltip='on'
                                        min={minMarginPercentage}
                                        max={100}
                                        value={inputMarginPercentage.value}
                                        onChange={changeEvent => inputMarginPercentage.setValue(changeEvent.target.value)}
                                        variant={inputMarginPercentage.value < 0 ? 'warning' : 'primary'}
                                    />
                                    <DivError error={inputMarginPercentage.error}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputRates">Rates</label>
                                    <textarea
                                        id="inputRates"
                                        placeholder="Rates"
                                        className={"form-control " + (inputRates.error.length > 0 ? "is-invalid" : '')}
                                        {...inputRates.bind}>
                                </textarea>
                                    <PhpToJsonInfo/>

                                    <JsonValidate value={inputRates.value}/>

                                    {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('geo')) && inputRates.value != "" &&
                                        <Alert
                                            messagePrefix="Postback error:"
                                            message={"To use this field, add the `geo` parameter to the postback URL. Template: &geo={geo}"}
                                            className={"alert-warning"}
                                        />
                                    }

                                    <DivError error={inputRates.error}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputMinValue">Min postback value</label>
                                    <input
                                        type="text"
                                        id="inputMinValue"
                                        placeholder="min postback value"
                                        className={"form-control " + (inputMinValue.error.length > 0 ? "is-invalid" : '')}
                                        {...inputMinValue.bind}
                                    />
                                    <DivError error={inputMinValue.error}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputMaxValue">Max postback value</label>
                                    <input
                                        type="text"
                                        id="inputMaxValue"
                                        placeholder="max postback value"
                                        className={"form-control " + (inputMaxValue.error.length > 0 ? "is-invalid" : '')}
                                        {...inputMaxValue.bind}
                                    />
                                    <DivError error={inputMaxValue.error}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputCustomSettings">Custom Settings</label>
                                    <textarea
                                        id="inputCustomSettings"
                                        placeholder="Custom Settings"
                                        className={"form-control " + (inputCustomSettings.error.length > 0 ? "is-invalid" : '')}
                                        {...inputCustomSettings.bind}>
                                </textarea>
                                    <CustomSettingsTemplate/>

                                    <JsonValidate value={inputCustomSettings.value}/>

                                    {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('geo')) && inputCustomSettings.value != "" &&
                                        <Alert
                                            messagePrefix="Postback error:"
                                            message={"To use this field, add the `geo` parameter to the postback URL. Template: &geo={geo}"}
                                            className={"alert-warning"}
                                        />
                                    }

                                    <DivError error={inputCustomSettings.error}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputSubsPM">Subscription Payout Multiplier</label>
                                    <textarea
                                        id="inputSubsPM"
                                        placeholder="Subscription Payout Multiplier"
                                        className={"form-control " + (inputSubsPM.error.length > 0 ? "is-invalid" : '')}
                                        {...inputSubsPM.bind}>
                                </textarea>
                                    <SubscriptionMPTemplate/>

                                    <JsonValidate value={inputSubsPM.value}/>

                                    {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('scnt')) && inputSubsPM.value != "" &&
                                        <Alert
                                            messagePrefix="Postback error:"
                                            message={"To use this field, add the `scnt` parameter to the postback URL. Template: &scnt={sub_cnt}"}
                                            className={"alert-warning"}
                                        />
                                    }

                                    <DivError error={inputSubsPM.error}/>
                                </div>
                            </>}

                            <div className="form-group">
                                <ButtonSubmit isLoading={false} text="Save changes"/>
                            </div>
                        </form>

                        <PreviewTable
                            forwardPercentage={parseInt(inputForwardPercentage.value)}
                            marginPercentage={parseInt(inputMarginPercentage.value)}
                            precision={parseInt(inputPrecision.value)}
                        />
                    </>}
                </LoadingWrapper>
            </Card>

            <ZoneInfo zone_id={zone_id} user={user}/>

            {isDebug() && <Card><Dump value={data}/></Card>}
        </Container>
    );
}

export default PostbackSetupZonesFormCreate;
