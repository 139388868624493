import React from "react";

import {round} from "../../../components/Math";
import ListData from "../../../components/ListData";
import {printManagerName} from "../../../components/user";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

export default function TrafficSourcesListTableParams(user) {
    const keyId = 'id';
    const keyManagerName = 'manager_id';
    const keyButtonEdit = 'edit';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: keyId,
            },
            {
                Header: 'Enabled',
                accessor: 'status',
                Cell: ({row}) => ListData.boolName(row.original['status']),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            // {
            //     Header: 'Network',
            //     accessor: 'network_name',
            // },
            {
                Header: 'Manager',
                accessor: keyManagerName,
                Cell: ({row}) => printManagerName(user, row.original[keyManagerName]),
            },
            {
                Header: 'Balance',
                accessor: 'balance',
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.balance) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                accessor: keyButtonEdit,
                className: 'min',
                Cell: ({row}) => <Link to={"/collections/traffic-sources/" + row.original['id'] + "/update"}><Icon.Settings/></Link>
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyId,
                desc: true
            }
        ]
    }

    return {
        keyManagerName,
        keyButtonEdit,
        columns,
        initialState
    };
}
