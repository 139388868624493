import React from 'react';
// import PropTypes from 'prop-types';

export default function DivFormGroupRow({children, label = '', labelAttributes = {}}) {
    return <div className="form-group row">
        <label className={"col-form-label col-sm-2 text-sm-right"} {...labelAttributes}>{label}</label>
        <div className={"col-sm-10"}>{children}</div>
    </div>
}

// DivFormGroupRow.propTypes = {
//     children: PropTypes.oneOfType([
//         PropTypes.arrayOf(PropTypes.element),
//         PropTypes.element.isRequired,
//         PropTypes.string.isRequired
//     ]),
//     label: PropTypes.string,
// }

DivFormGroupRow.defaultProps = {
    children: "",
    label: "",
}
