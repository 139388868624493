import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import {consoleLog} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePutRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import {useInput, useSelect} from "../../../components/input-hook";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";
import DivError from "../../components/form/DivError";
import SelectSingle from "../../components/select/SelectSingle";
import {SelectDataFormatter} from "../../../components/select_data";

const TrafficSourceFormEdit = ({user}) => {
    let {id} = useParams();

    const inputStatus = useSelect('1');
    const inputSubName = useInput('');

    const [{data, isLoading, error}] = useGetRequest('traffic-source/' + id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePutRequest('traffic-source/' + id);

    useEffect(() => {
        consoleLog(dataErrors)
        let errors = {};
        dataErrors.forEach(v => {
            errors[v.field] = v.message;
        })

        inputStatus.setError(errors.status ?? '');
        inputSubName.setError(errors.sub_name ?? '');
    }, [dataErrors]);

    const setFromResponse = (result) => {
        consoleLog(result);

        inputStatus.setValue(result.status ?? '')
        inputSubName.setValue(result.sub_name ?? '')
    }

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            inputStatus.setOptions(SelectDataFormatter.bool());
            // inputGroup.setOptions(SelectDataFormatter.managerGroups(user.getManagerGroups()));
            setFromResponse(data);
        }
    }, [data]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            status: inputStatus.value,
            sub_name: inputSubName.value,
        };
        setRequestData(data);
    }

    return (
        <Container title={"Edit traffic source: " + id}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {
                            errorUpdate &&
                            <AlertDanger messagePrefix={errorUpdate['name'] + ': '} message={errorUpdate['message']}/>
                        }
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <DivFormGroupRow label="Enabled" labelAttributes={{htmlFor: "inputStatus"}}>
                            <SelectSingle {...inputStatus.bind} />
                            <DivError error={inputStatus.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Sub name" labelAttributes={{htmlFor: "inputSubName"}}>
                            <input
                                type="text"
                                id="inputSubName"
                                placeholder="Sub name"
                                className={"form-control " + (inputSubName.error.length > 0 ? "is-invalid" : '')}
                                {...inputSubName.bind}
                            />
                            <DivError error={inputSubName.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow>
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </DivFormGroupRow>
                    </form>
                </LoadingWrapper>
            </Card>
        </Container>
    );
}

export default TrafficSourceFormEdit;
