import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";

import Lss from "./pages/Lss";
import Rates from "./pages/Rates";
import Settings from "./pages/Settings";
import Develop from "./pages/Develop";
import Logout from "./pages/Logout";
import Page404 from "./pages/Page404";
import Parent from "./Parent";
import ExStatDashboardList from "./pages/ExStat/Dashboard1/List";
import ExStatDashboard2List from "./pages/ExStat/Dashboard2/List";
import ExStatDashboard3List from "./pages/ExStat/Dashboard3/List";
import ExStatDashboard4List from "./pages/ExStat/Dashboard4/List";
import ExStatDashboardTotalList from "./pages/ExStat/DashboardTotal/DashboardTotalList";
import DataValidationSummaryList from "./pages/DataValidation/DataValidationSummaryList";
import DataValidationList from "./pages/DataValidation/DataValidationList";
import LandingLinkPage from "./pages/LandingLink/LandingLinkPage";
import LandingLinkPage2 from "./pages/LandingLink/LandingLinkPage2";
import LandingLinkPage3 from "./pages/LandingLink/LandingLinkPage3";
import DomainsList from "./pages/Domains/List";
import UsersList from "./pages/Users/UsersList";
import TrafficSourcesList from "./pages/TrafficSources/TrafficSourcesList";
import PostbacksList from "./pages/Postbacks/PostbacksList";
import ExStatCampaignClickaduCreateForm from "./pages/ExStat/Campaign/Clickadu/ExStatCampaignClickaduCreateForm";
import ExStatPlacementEditForm from "./pages/ExStat/Placement/EditForm";
import ExStatCampaignClickaduEditForm from "./pages/ExStat/Campaign/Clickadu/ExStatCampaignClickaduEditForm";
import ExStatCampaignItem from "./pages/ExStat/Campaign/Item";
import ExStatCampaignList from "./pages/ExStat/Campaign/List";
import ExStatZoneList from "./pages/ExStat/Zone/List";
import ZoneAssignForm from "./pages/ZoneAssign/ZoneAssignForm";
import ExStatZoneEditForm from "./pages/ExStat/Zone/EditForm";
import PropushEditForm from "./pages/ZonePropush/PropushEditForm";
import PropushCreateForm from "./pages/ZonePropush/PropushCreateForm";
import ExStatMappingForm from "./pages/ExStat/Mapping/Form";
import ZoneListPropush from "./pages/ZonePropush/ZonePropushList";
import ZoneListRollerads from "./pages/ZoneRollerads/ZoneRolleradsList";
import ZoneZeydooList from "./pages/ZoneZeydoo/ZoneZeydooList";
import ZoneGalaksionList from "./pages/ZoneGalaksion/ZoneGalaksionList";
import PostbackSetupDemo from "./pages/PostbackSetup/PostbackSetupDemo";
import PostbackSetupZonesFormCreate from "./pages/PostbackSetup/PostbackSetupZonesFormCreate";
import PostbackSetupZonesFormEdit from "./pages/PostbackSetup/PostbackSetupZonesFormEdit";
import PostbackSetupZonesList from "./pages/PostbackSetup/PostbackSetupZonesList";
import PostbackSetupCustomFormEdit from "./pages/PostbackSetup/PostbackSetupCustomFormEdit";
import PostbackSetupCustomFormCreate from "./pages/PostbackSetup/PostbackSetupCustomFormCreate";
import PostbackSetupCustomList from "./pages/PostbackSetup/PostbackSetupCustomList";
import Report1List from "./pages/Report1/Report1List";
import SegmentRatesList from "./pages/SegmentRates/List";
import SegmentDefaultsEditForm from "./pages/SegmentDefaults/EditForm";
import SegmentDefaultsList from "./pages/SegmentDefaults/List";
import {PERMISSIONS} from "../components/permissions";
import NftCollectionAddForm from "./pages/Nft/Collection/AddForm";
import NftCollectionList from "./pages/Nft/Collection/List";
import NftTokenList from "./pages/Nft/Token/List";
import Dashboard from "./pages/Dashboard";
import Offers from "./pages/Offers";
import Offer from "./pages/Offer";
import Payments from "./pages/Payments";
import Stats from "./pages/Stats";
import Terms from "./pages/static/Terms";
import Privacy from "./pages/static/Privacy";
import Help from "./pages/static/Help";
import Support from "./pages/static/Support";
import Empty from "./pages/Empty";
import UserFormEdit from "./pages/Users/UserFormEdit";
import UserFormCreate from "./pages/Users/UserFormCreate";
import TrafficSourceFormEdit from "./pages/TrafficSources/TrafficSourceFormEdit";

export default function RoutesMain({user, props}) {
    return <Routes>
        <Route path="*" element={<Page404/>} />
        <Route path="/" element={<Navigate to="/empty"/>}/>
        <Route path="/login" element={<Navigate to="/empty"/>}/>
        <Route path="/dashboard" element={<Parent/>}>
            <Route path="push" element={<ExStatDashboardList user={user}/>}/>
            <Route path="smartlink" element={<ExStatDashboard2List user={user}/>}/>
            <Route path="clickadu" element={<ExStatDashboard3List user={user}/>}/>
            <Route path="zeydoo" element={<ExStatDashboard4List user={user}/>}/>
            <Route path="total" element={<ExStatDashboardTotalList user={user}/>}/>
        </Route>
        <Route path="/data-validation" element={<Parent/>}>
            <Route path="summary" element={<DataValidationSummaryList user={user}/>}/>
            <Route path="by-account" element={<DataValidationList user={user}/>}/>
        </Route>
        <Route path="/collections" element={<Parent/>}>
            <Route path="landings" element={<LandingLinkPage user={user}/>}/>
            <Route path="landings2" element={<LandingLinkPage2 user={user}/>}/>
            <Route path="landings3" element={<LandingLinkPage3 user={user}/>}/>
            <Route path="domains" element={<DomainsList user={user}/>}/>
            <Route path="users" element={<UsersList user={user}/>}/>
            <Route path="users/:id/update" element={<UserFormEdit user={user}/>}/>
            <Route path="users/create" element={<UserFormCreate user={user}/>}/>
            <Route path="traffic-sources" element={<TrafficSourcesList user={user}/>}/>
            <Route path="traffic-sources/:id/update" element={<TrafficSourceFormEdit user={user}/>}/>
        </Route>
        <Route path="/postbacks" element={<PostbacksList user={user}/>}/>
        <Route path="/ex-stat/campaign/create" element={<ExStatCampaignClickaduCreateForm user={user}/>}/>
        <Route path="/ex-stat/campaign/:campaign_id/placement/:id/edit" element={<ExStatPlacementEditForm user={user}/>}/>
        <Route path="/ex-stat/campaign/:id/edit" element={<ExStatCampaignClickaduEditForm user={user}/>}/>
        <Route path="/ex-stat/campaign/:id" element={<ExStatCampaignItem user={user}/>}/>
        <Route path="/ex-stat/campaign" element={<ExStatCampaignList user={user}/>}/>
        <Route path="/ex-stat/account/:account_id" element={<ExStatZoneList user={user}/>}/>
        <Route path="/ex-stat/zone/assign/:zone_id" element={<ZoneAssignForm user={user}/>}/>
        <Route path="/ex-stat/zone/edit/:zone_id" element={<ExStatZoneEditForm user={user}/>}/>
        <Route path="/ex-stat/zone/edit-propush/:zone_id" element={<PropushEditForm user={user}/>}/>
        <Route path="/ex-stat/zone/create" element={<PropushCreateForm user={user}/>}/>
        <Route path="/ex-stat/mapping/add/:account_id" element={<ExStatMappingForm user={user}/>}/>
        <Route path="/zone" element={<Parent/>}>
            <Route path="propushme" element={<ZoneListPropush user={user}/>}/>
            <Route path="rollerads" element={<ZoneListRollerads user={user}/>}/>
            <Route path="zeydoo" element={<ZoneZeydooList user={user}/>}/>
            <Route path="galaksion" element={<ZoneGalaksionList user={user}/>}/>
        </Route>
        <Route path="/postback-setup/demo" element={<PostbackSetupDemo user={user}/>}/>
        <Route path="/postback-setup/zones/create/:zone_id" element={<PostbackSetupZonesFormCreate user={user}/>}/>
        <Route path="/postback-setup/zones/edit/:id" element={<PostbackSetupZonesFormEdit user={user}/>}/>
        <Route path="/postback-setup/zones" element={<PostbackSetupZonesList user={user}/>}/>
        <Route path="/postback-setup/custom/edit/:id" element={<PostbackSetupCustomFormEdit user={user}/>}/>
        <Route path="/postback-setup/custom/create" element={<PostbackSetupCustomFormCreate user={user}/>}/>
        <Route path="/postback-setup/custom" element={<PostbackSetupCustomList user={user}/>}/>
        <Route path="/reports" element={<Parent/>}>
            <Route path="report_1" element={<Report1List user={user}/>}/>
        </Route>

        {/* adminsIdList.includes(user.id)*/}

        <Route path="/segment-rate/" element={<SegmentRatesList {...props} user={user}/>}/>
        <Route path="/segment-defaults/edit/:segment_id" element={<SegmentDefaultsEditForm user={user}/>}/>
        <Route path="/segment-defaults/" element={<SegmentDefaultsList user={user}/>}/>
        {
            user.allowPermission(PERMISSIONS.DEVELOPER) && <>
                <Route path="/nft/collection/add/" element={<NftCollectionAddForm user={user}/>}/>
                <Route path="/nft/collections/" element={<NftCollectionList user={user}/>}/>
                <Route path="/nft/tokens/" element={<NftTokenList user={user}/>}/>
            </>
        }

        {/* @TODO clean old routes with backend controllers */}
        <Route path='/dashboard_old' element={<Dashboard/>}/>
        <Route path='/offers' element={<Offers/>}/>
        <Route path='/offer/:offerId' element={<Offer/>}/>
        <Route path='/payments' element={<Payments/>}/>
        <Route path='/stats' element={<Stats/>}/>
        <Route path='/settings' element={<Settings/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path='/help' element={<Help/>}/>
        <Route path='/support' element={<Support/>}/>

        <Route path='/stats' element={<Lss/>}/>
        <Route path='/rates' element={<Rates/>}/>

        <Route path='/empty' element={<Empty/>}/>
        <Route path='/dev' element={<Develop/>}/>
        <Route path='/logout' element={<Logout/>}/>

        {/*DEPRECATED ROUTES*/}
        <Route path="/ex-stat/dashboard" element={<ExStatDashboardList user={user}/>}/>
        <Route path="/ex-stat/dashboard_tb" element={<ExStatDashboard2List user={user}/>}/>
        <Route path="/ex-stat/dashboard_clickadu" element={<ExStatDashboard3List user={user}/>}/>
    </Routes>
}
