import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

import ListData from "../../../components/ListData";
import ExternalLink from "../../components/ExternalLink";
import {printManagerName} from "../../../components/user";

export default function UsersListTableParams(user) {
    const keyId = 'id';
    const keyGroupId = 'group_id';
    const keyRoles = 'roles';
    const keyButtonEdit = 'edit';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: keyId,
            },
            {
                Header: 'Group Id',
                accessor: keyGroupId,
            },
            {
                Header: 'Enabled',
                accessor: 'status',
                Cell: ({row}) => ListData.boolName(row.original['status']),
            },
            {
                Header: 'Team leader',
                accessor: 'supervisor_id',
                Cell: ({row}) => printManagerName(user, row.original['supervisor_id']),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Initials',
                accessor: 'initials',
            },
            {
                Header: 'Telegram',
                accessor: 'messenger',
                Cell: ({row}) => row.original['messenger'].length > 0
                    ? <ExternalLink url={'https://t.me/' + row.original['messenger'].replace(/^@/, '')} title={('@' + row.original['messenger']).replace(/^@@/, '@')} />
                    :  ''
            },
            {
                Header: 'Notes',
                accessor: 'notes',
            },
            {
                Header: 'Roles',
                accessor: keyRoles,
                Cell: ({row}) => row.original['roles'].join(', ')
            },
            {
                accessor: keyButtonEdit,
                className: 'min',
                Cell: ({row}) => <Link to={"/collections/users/" + row.original['id'] + "/update"}><Icon.Settings/></Link>
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyId,
                desc: true
            }
        ]
    }

    return {
        keyGroupId,
        keyRoles,
        keyButtonEdit,
        columns,
        initialState
    };
}


