import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

export default function Time({zone, className}) {
    const [headerDate, setHeaderDate] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setHeaderDate(moment().tz(zone).format("YYYY-MM-DD HH:mm:ss"));
        }, 1000);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={className}><b>{zone}:</b> {headerDate}</div>
}

Time.propTypes = {
    className: PropTypes.string,
    zone: PropTypes.string,
}

Time.defaultProps = {
    className: "a",
    zone: "EST",
}
